// custom variables
// @import 'variables'; // <-- loaded via sass-loader webpack config

// required bootstrap files
@import 'bootstrap/scss/bootstrap-reboot';

// optional bootstrap files
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/type';

// Uncomment in case you want to try out full bootstrap css for debugging
// style related issues, to find out how it looks with all bootstrap styles loaded
// and probably find out unloaded necessary part of bootstrap library
// @import '~bootstrap/scss/bootstrap';

// custom files
@import 'theme';

// Hiding reCaptcha badge
// source: https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge-what-is-allowed
.grecaptcha-badge {
  visibility: hidden;
}
