
        @import "variables";
        @import "bootstrap/scss/functions";
        @import "bootstrap/scss/variables";
        @import "bootstrap/scss/mixins";
      



























.page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 5rem);
  margin: 2.5rem 0;

  main {
    margin: 4rem 0;
  }
}

.page--content-only {
  justify-content: center;
}
