
        @import "variables";
        @import "bootstrap/scss/functions";
        @import "bootstrap/scss/variables";
        @import "bootstrap/scss/mixins";
      







































































































.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
}

.extra {
  -ms-flex: 0 1 auto; // IE fix
  flex: 1 1 0;
  min-width: 0;
  margin-bottom: 1rem;
  margin-right: 1rem;
  height: 100%;
  display: flex;
  align-items: center;

  > img {
    flex: 0 1 auto;
    margin-right: 0.5rem;
    vertical-align: text-top;
    height: 1.1rem;
    width: auto;
  }

  > .discreet-link {
    flex: 1 1 auto;

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}

::v-deep .right-button {
  -ms-flex: 0 1 auto; // IE fix
  margin-bottom: 1rem;

  @media (max-width: 400px) {
    width: 100%;
  }
}

::v-deep .full-button {
  margin-bottom: 1rem;
  width: 100%;
}
