
        @import "variables";
        @import "bootstrap/scss/functions";
        @import "bootstrap/scss/variables";
        @import "bootstrap/scss/mixins";
      































































::v-deep button.dropdown-toggle {
  color: $text-color-light;
  // add custom caret
  background: $custom-select-background;
  // make place for custom caret
  padding-right: 2rem;
  font-family: inherit;
  text-align: left;

  // remove bts caret
  &:after {
    content: none;
  }
}

.dropdown.show {
  ::v-deep .btn.dropdown-toggle {
    color: $text-color-dark;
    // fixes IE extra box-shadow addition
    transition-property: color, background-color, border-color;
  }

  ::v-deep .dropdown-menu {
    box-shadow: $box-shadow;
    border: none;
  }

  ::v-deep .dropdown-item {
    color: $text-color-light;
    &:hover {
      color: $text-color-dark;
    }
    &:active {
      background-color: transparent;
    }
  }
}

[no-border] {
  ::v-deep button.dropdown-toggle {
    background-color: transparent;
    border: none;
    position: relative;
    left: -0.7rem;
    padding-left: 0.7rem !important;

    &:active {
      color: $text-color-dark;
      background-color: transparent;
    }

    &:focus {
      outline: 0.0625rem dotted;
      outline: 0.3125rem auto -webkit-focus-ring-color; // 5px
    }
  }
}
