// DOCS: bootstrap variables list: https://github.com/twbs/bootstrap/blob/master/scss/_variables.scss

// env
$imagesPath: '~client/images/';
$fontsPath: '~client/fonts/';

@font-face {
  font-family: 'Bebas Neue';
  src: url('#{$fontsPath}BebasNeue-Web.eot');
  src: url('#{$fontsPath}BebasNeue-Web.eot?#iefix')
      format('embedded-opentype'),
    url('#{$fontsPath}BebasNeue-Web.woff2') format('woff2'),
    url('#{$fontsPath}BebasNeue-Web.woff') format('woff'),
    url('#{$fontsPath}BebasNeue-Web.ttf') format('truetype');
    // url('#{$fontsPath}BebasNeue-Web.svg#BebasNeue-Web') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// colors
$white: #fff;
$green: #82bd03;
$blue: #1083ff;
$red: #e4343d;
$helpdesk: #64bfa1;

$disabled-bg-color: #f9fafb;
$inactvie-bg-color: #eef0f3;
$text-color-helper: #b1bcc6;
$text-color-light: #949a9e;
$text-color-lighter: #d4d7d9;
$text-color-dark: #2c3742;
$lines-color: #e4e7e9;
$lines-color-hilight: $text-color-light;
$modal-backdrop-color: #242628;

$shadow: rgba(23,28,36,0.15);
$shadow2: rgba(23,28,36,0.7);

// fonts
$font-family-base: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI',
  Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol';
// Source: https://medium.com/needmore-notes/using-system-fonts-for-web-apps-bf76d214a0e0
// Source: https://caniuse.com/#feat=font-family-system-ui
$font-family-secondary: 'Bebas Neue', Helvetica, Arial, sans-serif;
$font-size-base: 0.875rem;
$font-size-lg: 1.375rem;

$headings-font-family: $font-family-secondary;
$btn-font-family: $font-family-secondary;
$body-color: $text-color-light;
$headings-color: $text-color-dark;

$letter-spacing: 0.0625rem;
$letter-spacing-btn: 0.1rem;

// borders
$border-part1-width: 0.0625rem; // 1px
$border-part2-width: 0.125rem; // 2px
$border-width: $border-part1-width + $border-part2-width;
$border-color: $lines-color;
$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;

$border: $border-width solid $lines-color;
$hilight: $border-width solid $lines-color-hilight;

$input-btn-focus-color: $lines-color-hilight;
$input-btn-focus-box-shadow: none;
$btn-focus-box-shadow: none;

$spinner-border-width-sm: 0.15rem;

// shadows
$box-shadow: 0 -0.5rem 2rem -0.5rem $shadow;
$box-shadow2: 0px 0.5rem 1rem -0.5rem $shadow2;

// headings spacing
// desired values "8px with paragraph, 24 with input and 16px with switcher thing"
// defined here: https://jira.websupport.sk/browse/P2FA-124?focusedCommentId=78406&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-78406
// h2 margin-bottom is 0.5rem = 8px

// paragraph case get automatically desired 8px
// input case
$h2-space-c2: calc(1.5rem - #{$border-width}); // 21px (21+3=24px desired), border-width compensation to mimic "bounding-box: padding-box"
// switcher thing case
$h2-space-c3: calc(1rem - #{$border-width}); // 13px (13+3=16px desired)

// inputs
$input-border-color: $border-color;
$input-padding-y-lg: 1rem;
$input-padding-x-lg: 0.6rem;
$input-font-size: 0.875rem;
$input-height-border: $border-width * 2;
$input-height-lg: calc(
  #{$input-font-size} + #{$input-padding-y-lg * 2} + #{$input-height-border}
);
$input-btn-line-height-lg: 1.25rem;
$input-btn-padding-y-lg: 1rem;
$input-btn-padding-x-lg: 1.375rem; // 22px
$input-btn-padding-bottom-lg: 0.7rem;
$btn-height-lg: calc(
  #{$input-btn-line-height-lg} + #{$input-btn-padding-y-lg} + #{$input-btn-padding-bottom-lg}
);

$floating-label: $text-color-helper;

// horisontal rule
$hr-border-color: $border-color;

// tooltip
$tooltip-bg: $text-color-dark;
$tooltip-padding-y: 0.5rem;
$tooltip-padding-x: 1rem;
$tooltip-opacity: 1;

$tooltip-arrow-width: 1.5rem;
$tooltip-arrow-height: 0.75rem;

// select
$custom-select-indicator: url('#{$imagesPath}Icon_arrow-point-down.svg');
