
        @import "variables";
        @import "bootstrap/scss/functions";
        @import "bootstrap/scss/variables";
        @import "bootstrap/scss/mixins";
      


































































































































.outdated-modal {
    height:100%;
    width:100%;
    top:0;
    left:0;
    z-index: 1000;
    position: fixed;
    display: none;

    &.show {
      display: block;

      @media (min-height: 47.5rem) { // 760px
        display: table;
      }
    }

		.grey-background{
			width:100%;
			height:100%;
			background-color:$modal-backdrop-color;
			opacity:0.7;
			z-index: 50;
			position: absolute;
		}

		.dialogbox-wrapper{
				z-index:1000;
				display: block;
				position:relative;
				text-align: center;
        max-height: 100%;
        overflow: scroll;
        height: 100%;

        @media (min-height: 47.5rem) { // 760px
          display: table-cell;
          vertical-align: middle;
          height: auto;
          max-height: 100%;
        }
		}

		.dialogbox-content{
        position: relative;
				border: $border;
				background-color: white;
				/* To center horizontally */
				display: inline-block;
        padding: 5rem;
        margin-top: 8rem;

        @media (max-width: 28.125rem) { // 450px
          padding: 2rem;
        }
		}
}

// modal content
h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.modal-img-wrap {
  width: 100%;
  height: 10em;
}

.modal-img {
  position: relative;
  width: 18rem;
  left: -2rem;
  top: -9rem;
}

.delimiter {
  margin-top: 3.625rem;
  position: relative;
  width: 100%;
  height: 2rem;

  hr {
    width: 90%;
    width: calc(100% - 3rem);
  }

  .text {
    position: absolute;
    width: 100%;
    height: 100%;
    top: -0.5rem;
    left: 0;
    text-transform: uppercase;
    letter-spacing: 0.15rem;
    font-size: 0.75rem;

    span {
      background: white;
      padding: 0 3rem;
    }
  }
}

.browser-links {
  .browser-link {
    margin: 1.5rem 2rem 0;
    text-align: left;
    height: 3rem;
    display: inline-block;

    @media (max-width: 47.5rem) { // 760px
      width: 100%;
    }

    &.left {
      float: left;
    }

    &.right {
      float: right;

      @media (max-width: 47.5rem) { // 760px
        float: left;
      }
    }

    img {
      margin-right: 1rem;
      width: 3rem;
      height: 3rem;
      vertical-align: middle;
    }

    .text {
      vertical-align: middle;
      display: inline-block;

      h3 {
        margin: 0;
      }
    }

    &:hover {
      text-decoration: none;

      span {
        text-decoration: underline;
      }
    }
  }
}

button.close {
  color: $text-color-light;
  text-shadow: none;
  font-weight: normal;
  font-size: 2rem;
  position: absolute;
  right: 1rem;
  top: 0.5rem;

  &:hover {
    color: $text-color-helper;
  }
}
