
        @import "variables";
        @import "bootstrap/scss/functions";
        @import "bootstrap/scss/variables";
        @import "bootstrap/scss/mixins";
      























.hr-delimiter {
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 100%;

	.line {
		height: 0.0625rem; // 1px
		min-width: 1rem;
		background-color: $border-color;
		flex: 1 1 auto;
	}

	.text {
		margin: 1.5rem;
		flex: 0 1 auto;
	}
}
