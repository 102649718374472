
        @import "variables";
        @import "bootstrap/scss/functions";
        @import "bootstrap/scss/variables";
        @import "bootstrap/scss/mixins";
      


















































// https://stackoverflow.com/questions/27713505/recaptcha-api-v2-styling

#recaptcha-container {
  display: flex;
  justify-content: center;

	& > div {
		margin-top: 2rem;
		margin-bottom: 2rem;
	}
}
