
        @import "variables";
        @import "bootstrap/scss/functions";
        @import "bootstrap/scss/variables";
        @import "bootstrap/scss/mixins";
      






































































































































































































































































































































































































































































































































































































.login-form {
  margin-bottom: 1rem;
}

.username {
  ::v-deep .form-label-group {
    margin-top: $h2-space-c2;
  }
}

.password {
  ::v-deep .form-label-group {
    margin-top: calc(1rem - (#{$border-width} * 2));
  }
}

.captcha {
  margin-top: 0.5rem;
  font-size: 0.8125rem;
}

.shrinked {
  display: block;
  overflow: hidden;
  height: 0;
}

.content ::v-deep button#bank-id-login {
  width: 100%;
  margin-bottom: 0.2rem;
  margin-top: 0.2rem;
}

.message.legacy-platform-notice {
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin: 1rem 0;
  padding: 1rem .5rem;

  .btn {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }

  img {
    width: 3rem;
    margin-right: 1rem;
    flex-shrink: 0;
  }

  p {
    margin-bottom: 0.5rem;
  }
}
