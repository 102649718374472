
        @import "variables";
        @import "bootstrap/scss/functions";
        @import "bootstrap/scss/variables";
        @import "bootstrap/scss/mixins";
      




























































































.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;

  .logo-anchor {
    -ms-flex: 0 1 auto; // IE fix
    margin-right: 1.5rem;

    .logo {
      height: 3rem;
      width: auto;
    }

    .logo-a24 {
      height: 2.5rem;
    }
  }

  .badge {
    -ms-flex: 0 1 auto; // IE fix
    flex: 0 1 auto;
    height: 1.5rem;
    width: auto;
  }
}
