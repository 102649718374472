
        @import "variables";
        @import "bootstrap/scss/functions";
        @import "bootstrap/scss/variables";
        @import "bootstrap/scss/mixins";
      
















































































































































::v-deep .input-group-text {
  border-left: none;
  background: none;
}

::v-deep input.appended {
  padding-right: 3rem;
}

.icon-wrap {
  height: 100%;
  width: 0;
  .icon {
    cursor: pointer;
    height: 1.25rem;
    width: 1.25rem;
    position: relative;
    left: -2.25rem;
  }
}

::v-deep .form-label-group {
  display: flex;
  align-items: center;
  width: 100%;
  input {
    flex: 1 1 auto;
    width: 100%;
  }
}

// workaround fix for empty prepend span
::v-deep .input-group-prepend {
  margin-right: 0;
}

// Removing clear and reveal password icon from IE10
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
