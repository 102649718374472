.discreet-link {
  text-decoration: none;
  color: $body-color;

  &:hover {
    color: darken($body-color, 10%);
  }
}

// fixes unwanted spaces between spans if you want
// to keep code readable aka each element on new line
// alternative solutions: https://stackoverflow.com/a/18262352/861615
p.composite-text {
  font-size: 0;
  span {
    font-size: 0.875rem;
  }
}

.capitalize {
  text-transform: capitalize;
}
.uppercase {
  text-transform: uppercase;
}