
        @import "variables";
        @import "bootstrap/scss/functions";
        @import "bootstrap/scss/variables";
        @import "bootstrap/scss/mixins";
      




























































































h2.white {
  color: $white;
  padding-right: 1.5rem;
}

button.close {
  color: $text-color-helper;
  text-shadow: none;
  font-weight: normal;
  font-size: 1.7rem;
  position: absolute;
  right: 0;
  top: -0.5rem;

  &:hover {
    color: $white;
  }
}

.text {
  margin: 0;
}

.flex {
  -ms-flex: 0 1 auto; // IE fix
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap-reverse;

  .link {
    margin-top: 0.5rem;
  }

  ::v-deep .action-button {
    margin-top: 0.5rem;

    @media (max-width: 400px) {
      width: 100%;
    }
  }
}
