
        @import "variables";
        @import "bootstrap/scss/functions";
        @import "bootstrap/scss/variables";
        @import "bootstrap/scss/mixins";
      





















































































.info {
  position: relative;
  color: $text-color-dark;
  font-weight: bolder;
  border: $border;
  padding: 1rem 2.5rem;
}

.info__icon {
  position: absolute;
  width: 3rem;
  left: -1.5rem;
  top: -1rem;
}
