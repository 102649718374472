
        @import "variables";
        @import "bootstrap/scss/functions";
        @import "bootstrap/scss/variables";
        @import "bootstrap/scss/mixins";
      

















.b-toast {
  max-width: none;
  .toast {
    background: $text-color-dark;
    border: none;
    border-radius: 0;
    box-shadow: $box-shadow;
    padding: 2rem;
    max-width: 25rem;

    @media (max-width: 400px) {
      padding: 1rem;
    }

    .toast-header {
      background: transparent;
      color: $white;
      padding: 0;
      position: relative;
      min-height: 0;

      button.close {
        color: $text-color-helper;
        text-shadow: none;
        font-weight: normal;
        font-size: 1.7rem;
        position: absolute;
        right: 0;
        top: -0.5rem;

        &:hover {
          color: $white;
        }
      }
    }
    .toast-body {
      color: $text-color-lighter;
      padding: 0;
    }
  }
}
