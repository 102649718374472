
        @import "variables";
        @import "bootstrap/scss/functions";
        @import "bootstrap/scss/variables";
        @import "bootstrap/scss/mixins";
      









































.bread-crumbs {
  color: $text-color-helper;

  .active {
    color: $text-color-dark;
    font-weight: bolder;
  }
}

.bread-crumbs__delimiter {
  display: inline-block;
  position: relative;
  margin: 0 2rem;
  height: 1rem;
  width: 4rem;
  top: -0.3rem;
  border-bottom: 0.0625rem solid $text-color-helper;
}
