
        @import "variables";
        @import "bootstrap/scss/functions";
        @import "bootstrap/scss/variables";
        @import "bootstrap/scss/mixins";
      

















  .ws-toaster-bottom-right {
    position: fixed;
		right: 1.5rem;
		bottom: 1.5rem;

		@media (max-width: 400px) {
			right: 0.2rem;
			left: 0.2rem;
			bottom: 0.5rem;
		}
  }
