
        @import "variables";
        @import "bootstrap/scss/functions";
        @import "bootstrap/scss/variables";
        @import "bootstrap/scss/mixins";
      














































































.account-switch {
  > .img > img {
    width: 50px;
  }
  > .body {
    &.standard {
      padding-left: 20px;

      > .title > h3 {
        margin: 0;
      }
    }
    > .text {
      margin-bottom: 1rem;
    }
    > .link {
      font-weight: bold;
      > a > svg {
        height: 0.7rem;
        width: auto;
        max-width: 2rem;
      }
    }
  }
}
