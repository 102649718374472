
        @import "variables";
        @import "bootstrap/scss/functions";
        @import "bootstrap/scss/variables";
        @import "bootstrap/scss/mixins";
      























































































.user-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  width: 100%;
  height: 3.5rem;
  color: $text-color-dark;
  overflow: hidden;

  & > *:not(:first-child) {
    margin-left: 1rem;
  }

  &.disabled {
    opacity: 0.5;
    background: $disabled-bg-color;
    cursor: not-allowed;
  }
}

.avatar {
  width: 2rem;
  flex-shrink: 0;
}

.username {
  flex-grow: 1;
  font-weight: bolder;

  .additional {
    color: $text-color-light;
  }
}

.extra {
  flex-shrink: 0;
}
