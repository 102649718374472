
        @import "variables";
        @import "bootstrap/scss/functions";
        @import "bootstrap/scss/variables";
        @import "bootstrap/scss/mixins";
      















































































$c-color: $white;

.error-note {
  display: flex;
  margin: 1rem 0;
  color: $red;
}

.icon {
  position: relative;
  width: 3rem;
  margin-right: 1rem;
  flex-shrink: 0;
}

.message {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.message__list {
  flex: 1;
  list-style: none;
  padding: 0;
  margin: 0;
}

.d-xs-none {
  @media (max-width: 20rem) {
    display: none;
  }
}
