
        @import "variables";
        @import "bootstrap/scss/functions";
        @import "bootstrap/scss/variables";
        @import "bootstrap/scss/mixins";
      







































































































.note {
  margin: 2.5rem 0;
}

.links {
  display: flex;
  justify-content: space-between;
  color: $text-color-light;
  margin-top: 1rem;

  ::v-deep .btn.dropdown-toggle {
    padding-left: 0;
  }

  .links-wrapper {
    display: flex;
    align-items: center;

    a {
      color: $text-color-light;

      &:not(:first-of-type) {
        margin-left: 1rem;
      }
    }
  }
}
