
        @import "variables";
        @import "bootstrap/scss/functions";
        @import "bootstrap/scss/variables";
        @import "bootstrap/scss/mixins";
      










































img.mojeid {
  display: block;
  height: 42px;
  margin: 0 auto;
  cursor: pointer;

  &:hover {
    opacity: 0.85;
  }
  &:active {
    opacity: 1;
    filter: brightness(0.9);
  }
}
