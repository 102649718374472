
        @import "variables";
        @import "bootstrap/scss/functions";
        @import "bootstrap/scss/variables";
        @import "bootstrap/scss/mixins";
      





























































































































#webauthn-wrapper {
  margin-top: 2rem;

  a.disabled {
    color: $text-color-light;
    cursor: not-allowed;
    text-decoration: none;
  }

  ::v-deep #webauthn-button {
    margin-top: 25px;
    text-align: right;

    @media (max-width: 400px) {
      text-align: center;

      button {
        width: 100%;
      }
    }
  }

  #webauthn-not-supported {
    padding-top: 15px;
    color: $red;
  }

  #fingerprint-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .trusted-checkbox {
    margin: 20px 0 9px 5px;
  }
}
