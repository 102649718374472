
        @import "variables";
        @import "bootstrap/scss/functions";
        @import "bootstrap/scss/variables";
        @import "bootstrap/scss/mixins";
      






























































































































.info {
  font-weight: 500;
  margin-bottom: 1.5rem;

  ::v-deep ul {
    padding-left: 0.5rem;
  }
}

.login-form {
  margin-bottom: 1rem;
}

.username {
  ::v-deep .form-label-group {
    margin-top: $h2-space-c2;
  }
}

.password {
  ::v-deep .form-label-group {
    margin-top: calc(1rem - (#{$border-width} * 2));
  }
}

.captcha {
  margin-top: 0.5rem;
  font-size: 0.8125rem;
}

.terms-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  font-weight: bolder;
  cursor: pointer;
}

::v-deep .terms-checkbox-invalid .custom-control-input ~ .custom-control-label {
  color: $red;

  &:before {
    border-color: $red;
  }
}

.d-xs-none {
  @media (max-width: 320px) {
    display: none;
  }
}

.shrinked {
  display: block;
  overflow: hidden;
  height: 0;
}
