
        @import "variables";
        @import "bootstrap/scss/functions";
        @import "bootstrap/scss/variables";
        @import "bootstrap/scss/mixins";
      



























































































.edit-btn {
  background: transparent;
  border: 0;
  padding: 0;
}

.edit-icon {
  width: 1rem;
  margin-left: 0.5rem;
}

::v-deep .user-item {
  margin-top: 1rem;
  border: $border;
  background-color: $disabled-bg-color;
}

// TODO: animations does not work yet as expected
// .height-enter-active,
// .height-leave-active {
//   transition: height 5s ease;
//   & > * {
//     position: absolute;
//     top: 0;
//     left: 0;
//   }
// }
// .height-enter, .height-leave-to
// /* .component-height-leave-active below version 2.1.8 */ {
//   height: 0;
//   overflow: hidden;
// }
