
        @import "variables";
        @import "bootstrap/scss/functions";
        @import "bootstrap/scss/variables";
        @import "bootstrap/scss/mixins";
      































































































.frame {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@mixin themed($color) {
  ::v-deep .theme-color {
    color: $color;
  }
  ::v-deep .theme-stroke {
    stroke: $color;
  }
  ::v-deep .theme-stop-color {
    stop-color: $color;
  }
  ::v-deep .theme-border {
    border-color: $color;
  }
  ::v-deep a.theme-color:hover {
    color: darken($color, 10%);
  }
  ::v-deep .theme-fill {
    fill: $color;
  }
  ::v-deep .theme-bg {
    background: $color;
  }
  ::v-deep .theme-bg-light {
    background: mix($color, white, 3%);
  }
  ::v-deep button.theme-bg:hover:not(:disabled),
  ::v-deep a.btn.theme-bg:hover:not(:disabled) {
    background: lighten($color, 5%);
  }
  ::v-deep button.theme-bg:active:not(:disabled),
  ::v-deep a.btn.theme-bg:active:not(:disabled) {
    background: darken($color, 5%);
  }
  // checkboxes
  ::v-deep .theme-color-checkbox {
    & .custom-control-input:checked ~ .custom-control-label {
      color: $color;

      &:before {
        border-color: $color;
        background-color: $color;
      }
      &:after {
        border: $border-width solid $color;
      }
    }
  }
}

.frame--blue {
  @include themed($blue);

// temporary explicit admin/blue button style as it will be
// different until web admin redesign will be done
  ::v-deep button.btn.theme-bg.btn-lg {
    font-family: $font-family-base;
    font-size: $font-size-base;
    font-weight: 500;
    padding: 0.75rem 2.6875rem;
    line-height: 1rem;
    letter-spacing: initial;
    text-transform: none;
  }
}

.frame--green {
  @include themed($green);
}

.frame--hd {
  @include themed($helpdesk);
}

::v-deep button.theme-bg:disabled {
  background: $text-color-light;
}

::v-deep
  .theme-color-checkbox
  .custom-control-input:checked
  ~ .custom-control-label:after {
  background-image: $custom-checkbox-indicator-icon-checked;
  // following svg is provided by designer, but I do not see any difference
  // background-image: url("/build/images/Icon_check.svg");
  background-size: cover;
}

.frame__row {
  height: 100%;
  // max-height: $max-height; // disabled max height as Peter (the designer) wished
}

// vertical rule
.frame__col:not(:last-child)::after {
  background: $lines-color;
  width: $border-width;
  content: "";
  display: block;
  position: absolute;
  top: 10%;
  bottom: 10%;
  right: 0;
  height: 80%;
  margin: 0 2rem;
}

.temp {
  // TODO: to be removed once right side will be finished
  display: flex;
  justify-content: left;
}

.left {
  max-width: 26rem;
  @media (max-width: 400px) {
    max-width: 100%;
  }
}

::v-deep header {
  min-height: 5rem;
}
