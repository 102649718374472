
        @import "variables";
        @import "bootstrap/scss/functions";
        @import "bootstrap/scss/variables";
        @import "bootstrap/scss/mixins";
      
























































































button#bank-id-login {
  display: block;
  font-family: $font-family-secondary !important;
  text-align: center;
  padding-top: 0.7rem;
  padding-bottom: 0.5rem;
  font-size: 1.375rem;
  line-height: 1.25rem;

  .mr-18 {
    margin-right: 18px;
  }
}
