* {
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
 }

input,
select,
textarea {
  // FF autofill color removal
  filter: none;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    box-shadow: 0 0 0 5rem white inset;
  }
}

input:focus,
textarea:focus,
select:focus,
button:focus,
.btn:focus {
  outline: $hilight;
}

input.form-control::placeholder {
  color: $text-color-helper;
}

input.form-control-lg {
  font-size: $input-font-size;
}

body {
  h1,
  h2,
  h3 {
    letter-spacing: $letter-spacing;
    line-height: 1em;
    text-transform: uppercase;
  }

  h4,
  h5,
  h6 {
    font-family: $font-family-base;
    font-weight: bolder;
  }

  h2 {
    font-size: 1.75rem;
  }

  h4 {
    font-size: 1.125rem;
  }
}

input.form-control:focus {
  outline: none;
  border: $hilight;
}

input.invalid,
.invalid input,
.invalid input.box,
input.invalid:focus,
.invalid input:focus {
  border: $border-part1-width solid $red;
  box-shadow: 0 0 0 5rem white inset, 0 0 0 $border-part2-width $lines-color;
}

.custom-control-label:before {
  top: 0.2rem;
}

.custom-control-input:focus ~ .custom-control-label {
  &:before {
    outline: none;
    border: none;
  }
  &:after {
    outline: none;
    border: $hilight !important;
  }
}

.spinner-border.spinner-border-sm {
  vertical-align: middle;
}

.btn-group-toggle.btn-group label.btn.btn-secondary {
  background-color: $inactvie-bg-color;
  border-color: $border-color;
  color: $text-color-light;
  font-family: $font-family-base;
  font-weight: bolder;

  &.active {
    color: $text-color-dark;
    background-color: transparent;
  }
}

p b {
  font-weight: normal;
  color: $text-color-dark;
}

ul.dashed {
  list-style-type: none;
  padding: 0 0 0 1rem;
  & > li {
    text-indent: -1rem;
  }
  & > li:before {
    content: '-';
    margin-right: 0.5rem;
  }
}

.no-footer .modal-footer {
  display: none;
}

.modal-dialog.modal-md {
  max-width: 48rem;
}

.modal .modal-content {
  position: relative;
  padding: 3rem 5rem;
  border: 0.25rem solid $border-color; // 4px
  text-align: center;

  .modal-header {
    border: none;

    button.close {
      position: absolute;
      top: 0.3rem;
      right: 0.6rem;
      font-weight: normal;
      color: $text-color-light;
    }
  }
}

// credit: https://github.com/bootstrap-vue/bootstrap-vue/blob/ceeb70e3509db158e3ce91318b9333ef94449056/src/components/modal/_modal.scss
.modal-backdrop:not(.show):not(.fade) {
  opacity: $modal-backdrop-opacity;
}

.tooltip-inner {
  box-shadow: $box-shadow2;
}

.bread-crumbs {
  margin-bottom: 1.5rem;
}

// credit: https://stackoverflow.com/a/23374725/861615
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}